.validity {
  padding-right: 30px;
}

.sourceField {
  position: relative;
}

.label {
  display: block;
}

.sourceField:has(.sourceInput:invalid) .validity::after {
  position: absolute;
  /* content: "✖"; */
  /* content: "*"; */
  padding-left: 5px;
  /* color: #8b0000; */
  color: var(--color-pursuit-red)
}
.sourceField:has(.sourceInput:valid) .validity::after {
  position: absolute;
  /* content: "✓"; */
  padding-left: 5px;
  color: var(--color-green-700)
}
.sourceField:has(.sourceInput:placeholder-shown) .validity::after {
  display: none;
}

/* .sourceInput:invalid + .validity::after {
  position: absolute;
  content: "*";
  padding-left: 5px;
  color: var(--color-pursuit-red)
}

.sourceInput:valid + .validity::after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
  color: var(--color-green-700)
} */