.custom-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 40px;
  background-position: calc(100% - 3px) center;
  background-repeat: no-repeat;
  background-image: url('./Images/select-down-arrow.png');
  text-overflow: ellipsis;
}

select,
option {
  font: -moz-pull-down-menu;
}

.primary-blue-text {
  color: #0f2b4d;
}

.light-primary-blue-text {
  color: #102b4d;
}

.standard-text-font {
  font-family: 'Roboto Condensed';
}

.header-text-font {
  font-family: 'Oswald';
}

.textfield-bg-color {
  background-color: #f5f5f5;
}

.highcharts-container {
  margin: 0 auto;
  overflow: visible !important;
}

.cluster {
  display: none !important;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.markdown-content p:first-of-type {
  margin-top: 0;
}
.markdown-content p:last-of-type {
  margin-bottom: 0;
}
.markdown-content p {
  margin: 1.5rem 0;
}
.notifications a,
.markdown-content a {
  color: #2b6cb0;
  text-decoration: underline;
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  /* border: 1px solid black; */
  /* padding: 0.5rem; */
  /* font: inherit; */
  margin-bottom: 0.5rem;
  /* margin-bottom: 3rem; */
  padding: 0 0.25rem 1.5rem;
  margin-left: -0.25rem;
  min-height: 6rem;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
/*
.clamp-toggle {

}
.clamp-toggle.collapsed + 
*/
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

[role='tooltip'],
.hidetooltip.hidetooltip.hidetooltip + [role='tooltip'] {
  visibility: hidden;
  position: absolute;
	top: 0;
	left: 0;
  /* top: 2rem;
  left: 2rem;
  background: black;
  color: white; */
}
[aria-describedby]:hover,
[aria-describedby]:focus {
  position: relative;
}
[role="tooltip"]:hover,
[role="tooltip"]:focus,
[aria-describedby]:hover + [role='tooltip'],
[aria-describedby]:focus + [role='tooltip'] {
  visibility: visible;
}
